import React from "react";

export const Funding = (props) => {
  return (
    <div id="funding" className="text-center py-12 bg-gray-100">
      <div className="container">
        <div className="section-title">
          <h2>{props.data?.subtitle || "Our Funding Journey"}</h2>
          <p>
            {props.data?.description ||
              "Explore our funding rounds and see how we’ve grown over time."}
          </p>
        </div>
        <div className="row">
          <div className="funding-items">
            {props.data && props.data.rounds
              ? props.data.rounds.map((round, i) => (
                  <div
                    key={`${round.stage}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4 mb-4"
                  >
                    <div className="bg-white p-6 shadow-lg rounded-lg h-full">
                      <h3 className="text-xl font-bold text-gray-700">
                        {round.stage}
                      </h3>
                      <p className="text-lg text-green-600 font-semibold">
                        {round.amount || round.goal}
                      </p>
                      <p className="text-sm text-gray-500 mt-2">
                        {round.date || round.anticipatedDate}
                      </p>
                      {round.investors && (
                        <p className="text-sm text-gray-500 mt-2">
                          <span className="font-semibold">Investors:</span>{" "}
                          {round.investors.join(", ")}
                        </p>
                      )}
                      <p className="text-gray-600 mt-4">{round.summary}</p>
                    </div>
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
